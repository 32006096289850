import React from "react";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { getLogsOfRowId } from "../../services/plot.service";
import { convertFirebaseTimestampToString } from "../../services/helpers";
import Alert from "../Alert/Alert.component";
import Separator from "../Separator.component";
import Loading from "../Loading.component";

const DataLog = (props: any) => {
    const { selectedRows, dataOrigin } = props;

    const [alert, setAlert] = useState({type: "", message: ""});
    const [showModalDataLog, setShowModalDataLog] = useState(false);
    const [logs, setLogs] = useState([] as any);
    const [dirty, setDirty] = useState(false);
    const [filter, setFilter] = useState({ limit: 100, order: "asc"});
    const [loadingLogs, setLoadingLogs] = useState(false);
    const [countFullLog, setCountFullLog] = useState(0);
    const [isRawLog, setIsRawLog] = useState(false);

    useEffect(() => {
        if (showModalDataLog) {
            if (selectedRows && selectedRows[0] && selectedRows[0].id)
            getLogsOfSelectedRow();
            return;
        }

        setLogs([]);
    }, [showModalDataLog]);

    const getLogsOfSelectedRow = async () => {
        setLoadingLogs(true);
        const payload: any = {
            rowId: selectedRows[0].id,
            dataOrigin,
            limit: filter.limit,
            order: filter.order
        };

        setLogs([]);
        setIsRawLog(false);

        await getLogsOfRowId(payload).then((res: any) => {
            setDirty(false);
            if (res.message === "OK") {

                if (res.rawLog) {
                    setIsRawLog(true);
                }

                setLogs(res.logfile);
                setCountFullLog(res.countFullLog);
                setLoadingLogs(false);
                return;
            }

            setCountFullLog(0);
            setLoadingLogs(false);
            setAlert({type: "danger", message: res.message});
            console.log('Error getLogsOfRowId', res);
        }).catch((err: any) => {
            setCountFullLog(0);
            setAlert({type: "danger", message: err.message});
            setLoadingLogs(false);
            console.log('Catch getLogsOfRowId', err);
        });
    };

    const _downloadLogs = () => {
        // Convert ARRAY to simple string, one line per entry
        let logsString = "";
        logs.forEach((log: any) => {
            logsString += JSON.stringify(log, null, 2).replace(/\\n/g, "") + "\n";
        });

        // logsString || JSON.stringify(logs, null, 2)
        const element = document.createElement("a");
        const file = new Blob([logsString], {type: 'text/plain'});
        element.href = URL.createObjectURL(file);
        element.download = `${selectedRows[0]?.id || "Logs"}-${logs.length || ""}-entries.txt`;
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
        return false;
    };

    return <>
        <Alert alert={alert} />

        <div className="DataLog">
            <div className="d-grid">
                <button
                type='button'
                disabled={selectedRows && (selectedRows.length === 0 || selectedRows.length > 1)}
                onClick={() => setShowModalDataLog(true)}
                className='btn btn-outline-secondary'>View Logs</button>
            </div>

            <Modal show={showModalDataLog} size="xl">
                <Modal.Header closeButton onClick={() => setShowModalDataLog(false)}>
                    <Modal.Title>Log Viewer</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedRows && selectedRows[0] && selectedRows[0].id && <>
                        <small className="text-muted">Doc ID: {selectedRows[0].id}</small>

                        {!isRawLog && <>
                            <Separator size={20} />

                            <div className="row align-items-end">
                                <div className="col-12 col-md-3">
                                    <div className="form-group">
                                        <label>List Entries</label>
                                        <select
                                            className="form-control"
                                            defaultValue={filter.limit}
                                            disabled={loadingLogs}
                                            onChange={(e) => {
                                                setFilter({...filter, limit: parseInt(e.target.value)});
                                                setLogs([]);
                                                setDirty(true);
                                            }}>
                                                <option value={100}>100</option>
                                                <option value={300}>300</option>
                                                <option value={1000}>1000</option>
                                                <option value={2000}>2000</option>
                                                <option value={0}>All entries</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <label>From</label>
                                        <select
                                            className="form-control"
                                            defaultValue={filter.order}
                                            disabled={loadingLogs}
                                            onChange={(e) => {
                                                setFilter({...filter, order: e.target.value});
                                                setLogs([]);
                                                setDirty(true);
                                            }}>
                                                <option value="asc">Begining (equivalent to the first N items)</option>
                                                <option value="desc">End (equivalent to the last N items)</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-12 col-md-3">
                                    <button
                                        type="button"
                                        className="btn btn-outline-primary"
                                        disabled={loadingLogs}
                                        onClick={() => getLogsOfSelectedRow()}>
                                            {loadingLogs && <Loading loading={loadingLogs} parent="inline" />}
                                            {!loadingLogs && <i className="fas fa-check me-2"></i>} Apply
                                    </button>
                                </div>
                            </div>
                        </>}
                    </>}

                    {!isRawLog && <>
                        <Separator size={10} />

                        <small className="text-muted">

                            {filter.limit === 0 && <>
                                Will show all entries of {countFullLog}
                            </>}

                            {filter.limit !== 0 && <>
                                Will fetch the <strong className="text-primary">{filter.order === "asc" ? "first" : "last"} {filter.limit}</strong> entries of {countFullLog}
                            </>}

                            {filter.order === "desc" && countFullLog > 0 && <>
                                <br />
                                <div>It shows -1 (lines) because arrays start from 0</div>
                            </>}
                        </small>
                    </>}

                    <Separator size={20} />

                    <Loading loading={loadingLogs} />


                    {isRawLog && <>
                        <div className="row">
                            <div className="col">
                                <ul className="list-unstyled">
                                    <li><strong>_channel: </strong> {logs._channel}</li>
                                    <li><strong>_id: </strong> {logs._id}</li>
                                    <li><strong>reader_id: </strong> {logs.reader_id}</li>
                                    <li><strong>assay_manager_version: </strong> {logs.assay_manager_version}</li>
                                    <li><strong>chip_type: </strong> {logs.chip_type}</li>
                                    <li><strong>Channels (assumed by <i>chip_type</i>): </strong> {logs.label_channels}</li>
                                    <li><strong>Channels (calculated by data): </strong> -</li>
                                    <li><strong>filename: </strong> {logs.filename}</li>
                                    <li><strong>sensor_config_version: </strong> {logs.sensor_config_version}</li>
                                    <li><strong>stage: </strong> {logs.stage}</li>
                                    <li><strong>createdAt: </strong> {convertFirebaseTimestampToString(logs.createdAt)}</li>
                                    <li><strong>elapsed_time: </strong> {convertFirebaseTimestampToString(logs.elapsed_time)}</li>
                                    <li><strong>start_time: </strong> {convertFirebaseTimestampToString(logs.start_time)}</li>
                                    <li><strong>total_duration: </strong> {logs.total_duration}</li>
                                    <li><strong>Total Stages (calculated): </strong> {logs.total_stages}</li>
                                    <li><strong>rawdata: </strong> <span className="text-muted">in the right column &rarr;</span></li>
                                </ul>
                            </div>
                            <div className="col">

                                {logs.rawdata && logs.rawdata.length > 0 && <>
                                    <div className="accordion" id="accordionExample">

                                        {logs.rawdata.map((raw: any, index: number) => {
                                            return <React.Fragment key={`raw-log-${index}`}>
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header">
                                                    <button
                                                        className="accordion-button collapsed"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target={`#collapseOne-${index}`}
                                                        aria-expanded="true"
                                                        aria-controls={`collapseOne-${index}`}>
                                                            time: {raw.time} (stage: {raw.label_stage}) - diff: {parseFloat(raw.differenceFromPreviousTime).toFixed(3)}
                                                    </button>
                                                    </h2>
                                                    <div id={`collapseOne-${index}`} className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                        <div className="accordion-body">

                                                            <div className="row">
                                                                <div className="col">
                                                                    <ul className="list-unstyled">
                                                                        <li><strong>E Begin: </strong> {raw["E Begin"]}</li>
                                                                        <li><strong>E End: </strong> {raw["E End"]}</li>
                                                                        <li><strong>E Precharge: </strong> {raw["E Precharge"]}</li>
                                                                        <li><strong>E Step: </strong> {raw["E Step"]}</li>
                                                                        <li><strong>avg_current: </strong> {raw["avg_current"]}</li>
                                                                        <li><strong>channel: </strong> {raw["channel"]}</li>
                                                                        <li><strong>data_count: </strong> {raw["data_count"]}</li>
                                                                        <li><strong>most_recent_flag: </strong> {raw["most_recent_flag"]}</li>
                                                                        <li><strong>process_mode: </strong> {raw["process_mode"]}</li>
                                                                        <li><strong>time: </strong> {raw["time"]}</li>
                                                                        <li><strong>stage: </strong> {raw["stage"]}</li>
                                                                    </ul>
                                                                </div>
                                                                <div className="col">
                                                                    {raw.currents && raw.currents.length > 0 && <>
                                                                        <div className="accordion" id="accordionExampleCurrents">

                                                                            <React.Fragment key={`current-raw-${index}-${raw.time}`}>
                                                                                <div className="accordion-item">
                                                                                    <h2 className="accordion-header">
                                                                                    <button
                                                                                        className="accordion-button"
                                                                                        type="button"
                                                                                        data-bs-toggle="collapse"
                                                                                        data-bs-target={`#collapseTwo-currents-${index}-${raw.time}`}
                                                                                        aria-expanded="true"
                                                                                        aria-controls={`collapseTwo-currents-${index}-${raw.time}`}>
                                                                                            {raw.currents.length} Currents
                                                                                    </button>
                                                                                    </h2>
                                                                                    <div
                                                                                        id={`collapseTwo-currents-${index}-${raw.time}`}
                                                                                        className="accordion-collapse collapse show"
                                                                                        data-bs-parent="#accordionExampleCurrents">
                                                                                            <div className="accordion-body">
                                                                                                <ul>
                                                                                                    {raw.currents.map((current: any, currentIndex: number) => {
                                                                                                        return <li key={`current-raw-${currentIndex}-${raw.time}`}>{current}</li>
                                                                                                    })}
                                                                                                </ul>
                                                                                            </div>
                                                                                    </div>
                                                                                </div>
                                                                            </React.Fragment>
                                                                            
                                                                        </div>
                                                                    </>}
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        })}

                                    </div>
                                </>}

                            </div>
                        </div>
                        
                    </>}


                    {!isRawLog && !dirty && <>
                        {!loadingLogs && logs.length === 0 && <strong>No logs found.</strong>}

                        {!loadingLogs && logs.length > 0 && <>
                            <button
                                type="button"
                                className="btn btn-outline-primary"
                                onClick={() => _downloadLogs()}>
                                    <i className="fas fa-download me-2"></i> Download Log
                            </button>

                            <Separator size={20} />

                            <ul className="list-group">
                                {logs.map((log: any, index: number) => {
                                    return (
                                        <li key={`log-entry-${index}`} className="list-group-item">
                                            <small className="text-muted me-2">
                                                {filter.order === "asc" ? index : (countFullLog-1) - index}
                                            </small> {JSON.stringify(log, null, 2).replace(/\\n/g, "")}
                                        </li>
                                    );
                                })}
                            </ul>
                        </>}
                    </>}

                    <Separator size={20} />

                    <button type="button" className="btn btn-outline-primary" onClick={() => setShowModalDataLog(false)}>
                        Close
                    </button>
                </Modal.Body>
            </Modal>

        </div>
    </>
};

export default DataLog;